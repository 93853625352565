Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
  });
export default async (context,inject) => {
    if(!context.store.state.labels.labels){
        const lang = context.route.params.lang
        var labels = await context.$axios.get("storyblok/stories",{
            params : { 
                flushcache : 'yes',
                per_page: 100,
                language: lang !== 'it' ? lang : 'default',
                starts_with : 'system/labels'
            }
        }).then(res => {
            labels = {}
            for(var story of res.data.stories){
                if(story.content && story.content.items){
                    for(var label of story.content.items){
                        labels[label.key] = label.text;
                    }
                }
            }
            return labels;
        }).catch(err => {
            console.log(err.stack);
            return {};
        })
        context.store.commit("labels/setLabels",labels);
    }
    inject('__', (...args) => {
        var s = args && args[0] ? args[0] : '';
        const key = s.replace(/-/gm,'');
        const placeholder = s.replace('breadCrumbsLabels','').replace(/-/gm,' ').replace(/%20/gm,' ').capitalize();
        s = context.store.state.labels.labels[key] ? context.store.state.labels.labels[key] : placeholder;
        if(args.length > 1){
            for(var i = 1; i < args.length ; i++){
                s = s.replace("%s",args[i]);
            }
        }
        return s;
    });
    inject('__url', (u) => {
        if(!u || !u.substring) return u;
        u = u.substring(0,1) == '/' ? u : '/' + u;
        const lang = context.route.fullPath.substring(0,3);
        if(u.substring(0,3) != lang){
            u = lang + u;
        }
        return u;
    });
    inject('__removeUrlLastSlash',(u) => {
        var lastChar = u.substring(u.length-1,u.length);
        return lastChar == '/' ? u.substring(0,u.length-1) : u ;
    })
}