export default async function (context) {
    await context.store.dispatch('cart/initCart');

    if(!context.store.user || !context.store.user.jwt){
        var cookieJwt = context.app.$cookies.get("vtexAuth");
        if(!cookieJwt){
            if(process.client) window.location = "/"; else return context.redirect("/");
        }else{
            var jwt = require('jsonwebtoken');
            var decoded = jwt.decode(cookieJwt);
            if(decoded && decoded.name && decoded.value && decoded.email){
                await context.store.dispatch('user/authenticate',{data:decoded,context:context});
            }else{
                if(process.client) window.location = "/"; else return context.redirect("/");
            }
        }
    } 
}