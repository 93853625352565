export default async function (context) {
    await context.store.dispatch('cart/initCart');
    var cookieLang = context.app.$cookies.get('userLang')
    
    if(!cookieLang){
        if(context.route && context.route.params && context.route.params.lang){
            context.app.$cookies.set("userLang", context.route.params.lang);
            cookieLang = context.route.params.lang;
        }else{
            const navigatorLanguage = process.client && navigator && navigator.languages && navigator.languages[0] ? navigator.languages[0] : process.env.DEFAULT_LANGUAGE;
            const headers = context.req.headers;
            const acceptLanguage = headers && headers['accept-language'] ? headers['accept-language'] : null;
            const detectedLang  = process.server && acceptLanguage ? acceptLanguage.split(',')[0] : navigatorLanguage;
            const userLang = detectedLang.split("-")[0];
            context.app.$cookies.set("userLang", userLang)
            if([null, undefined].includes(context.route.params.lang) || !context.route.fullPath  || context.route.fullPath ==`/${context.route.params.lang}` ) {
                return context.redirect(`/${userLang}/`)
            }
            return context.redirect(context.route.fullPath.replace(`/${context.route.params.lang}/`, `/${userLang}/`)); 
        }
    }
    if(context.route.params.lang != cookieLang){
        if([null, undefined].includes(context.route.params.lang) || !context.route.fullPath  || context.route.fullPath ==`/${context.route.params.lang}` ) {
            return context.redirect(`/${cookieLang}/`)
        }
        return context.redirect(context.route.fullPath.replace(`/${context.route.params.lang}/`, `/${cookieLang}/`))
    }
    
    if( !context.route.fullPath || context.route.fullPath ==`/${context.route.params.lang}` ){
        return context.redirect(`/${context.route.params.lang}/`)
    }
    
    if(!context.store.user || !context.store.user.jwt){
        var cookieJwt = context.app.$cookies.get("vtexAuth");
        var jwt = require('jsonwebtoken');
        var decoded = jwt.decode(cookieJwt);
        if(cookieJwt && decoded && decoded.name && decoded.value && decoded.email){
            await context.store.dispatch('user/authenticate',{data:decoded, context:context});
        }
    } 

    if(!context.store.staff || !context.store.staff.jwt){
        var cookieJwt = context.app.$cookies.get("staffAuth");
        var jwt = require('jsonwebtoken');
        var decoded = jwt.decode(cookieJwt);
        if(cookieJwt && decoded && decoded.user && decoded.email){
            context.store.dispatch('staff/authenticate',{data:decoded, context:context});
        }
    } 
}