export const state = () => ({
    items : [],
    products : {},
    max : 3
})
export const mutations = {
    setItem(state,v) {
        state.items.push(v);
    },
    setProduct(state,v){
        if(Object.keys(state.products).length > 0) {
            for (let item in state.products){
                if(state.products[item].categories[state.products[item].categories.length -1 ] !== v.categories[v.categories.length -1]){
                    v.toastError('sameCategoryError')
                    let toRemove =state.items.indexOf(v.ean)
                    if(toRemove >= 0){
                        state.items.splice(toRemove, 1)
                        delete state.products[v.ean]
                    } 
                    return 
                }
            }
        }

        state.products[v.ean] = v;
    },
    removeItem(state,v){
        state.items.splice(state.items.indexOf(v),1);
        delete state.products[v]
    }
}