export const state = () => ({
    open: false,
    content: null,
    blokClass : null,
    component: null
});
export const mutations = {
    setModal(state,v){
        if(!v.open || !v){
            state.open = false;
            setTimeout(() => {
                if(!state.open) this.commit('modal/clearModal');
            }, 250);
        }else{
            state.open = v.open;
            state.content = v.content;
            state.component = v.component;
            state.blokClass = v.blokClass;
        }
    },
    clearModal(state){
        state.content = null;
        state.component = null;
        state.blokClass = null;
        state.open = false;
    }
}