export default function ({$axios, store, route, app, error: nuxtError, req, $config}) {
    if(process.client){
        if($axios.defaults.baseURL != $config.axiosBaseURL) $axios.defaults.baseURL = $config.axiosBaseURL;
    }
    $axios.onRequestError(error => {
        var errorRes = {};
        errorRes.status = 500;
        errorRes.message = 'apiDown';
        var message = errorRes.message && errorRes.message.label ? errorRes.message.label : errorRes.message;
        if (process.server) {
            nuxtError({ statusCode: errorRes.status, message: errorRes.message })
        }
        if (process.client) {
            app.$toastError(message)
        }
        return Promise.reject(false);
    })
    
    $axios.onResponseError(error => {
        var errorRes = {};
        errorRes.status = error.response && error.response.status ? error.response.status : 500;
        errorRes.message = error.response && error.response.data &&  error.response.data.error ? error.response.data.error : 'systemError';
        
        if(error && error.response && error.response.config && !error.response.config.ignoreErrors){
            if(errorRes.status == 401){
                if(process.server) nuxtError({ statusCode: errorRes.status, message: 'unauthorized' });
                if(process.client) window.location.href = app.$__url('/user/logout')
            }
            var message = errorRes.message && errorRes.message.label ? errorRes.message.label : errorRes.message;
            
            if (process.server) {
                nuxtError({ statusCode: errorRes.status, message: message });
            }
            if (process.client) {
                app.$toastError(message)
            }
        }
        
        return Promise.reject({response: errorRes});
    })

    $axios.onRequest(config => {
        config.headers["storyblok-access-token"] = process.client ? $config.storyblokAccesstoken : (process.env.ENVIROMENT == 'production' ? process.env.STORYBLOK_PRODUCTION_KEY : process.env.STORYBLOK_DEVELOPMENT_KEY);
        config.headers["X-ecommerce-group"] = process.client ? $config.ecommerceGroup : process.env.ECOMMERCE_GROUP;
        if(store.state.cart?.cart?.ownershipCookie){
            config.headers['ownership-cookie'] = store.state.cart?.cart?.ownershipCookie
        }        
        if(store.state.user && store.state.user.decodedJwt && store.state.user.decodedJwt.value){
            if(config.headers.common){
                config.headers.common.vtexUserToken = store.state.user.decodedJwt.value;
            }else{
                config.headers.vtexUserToken = store.state.user.decodedJwt.value;
            }
        }
        const lang = route.params.lang ? route.params.lang : (process.client ? $config.defaultLanguage : process.env.DEFAULT_LANGUAGE);
        config.headers.lang = lang
    })
  }