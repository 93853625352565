export default async (context,inject) => {
    if(!context.store.state.icons.icons){
        var icons = await context.$axios.get("storyblok/stories",{
            params : { 
                flushcache : 'yes',
                per_page: 100,
                starts_with : 'system/icons'
            }
        }).then(res => {
            icons = {}
            for(var story of res.data.stories){
                if(story.content){
                    for(var i in story.content.items){
                        icons[story.content.items[i].tag] = story.content.items[i].asset 

                    }
                }
            }
            return icons;
        }).catch(err => {
            return {};
        })
        context.store.commit("icons/setIcons",icons);
    }
    inject('__icon', (key) => {
        key = key.toLowerCase();
        const img = context.store.state.icons.icons[key] ? context.store.state.icons.icons[key] : null;
        return img;
    });
}