export const state = () => ({
    cart: {
        id: null,
        quantity: 0
    },
    cartObj : null,
    quantity : 0
});
export const mutations = {
    setCartId(state, obj){
        state.cart.id = obj.cartId;
        state.cart.ownershipCookie = obj.ownershipCookie;
        this.$cookies.set('userCart',state.cart,{path: '/', maxAge: 60 * 60 * 24 * 7})
    },
    setCartQuantity(state, int){
        state.cart.quantity = int;
        state.quantity = int;
        this.$cookies.set('userCart',state.cart,{path: '/', maxAge: 60 * 60 * 24 * 7})
    },
    clearCart(state){
        state.cart.id = null;
        state.cart.quantity = 0
    },
    setCartObj(state,v){
        state.cartObj = v;
    },
    setProfileData(state,v){
        state.cartObj.clientProfileData = v;
    },
    setShippingData(state,v){
        state.cartObj.shippingData = v;
    },
    setBillingData(state,v){
        state.cartObj.invoiceData = v;
    },
    setCustomData(state,v){
        state.cartObj.customData = v;
    }
}
export const actions = {
    async addToCart(storeContext,payload){
        this.$axios.post('/cart/addItem',payload).then(res => {
            storeContext.commit('setCartQuantity',res.data.items.length)
            this.$toastSuccess('productAddedToCart');
            this.$piwikUpdateCart(res.data);
            if(payload.callback) payload.callback();
        }).catch(err => {
            return null;
        })
    },
    async initCart(storeContext,obj){
        var cookie = this.app.$cookies.get('userCart');
        const cartId =  cookie ? cookie.id : null;
        const ownershipCookie = cookie?.ownershipCookie ?? null
        const CartCookie = {cartId, obj, ownershipCookie}
        if(!cartId){
            await this.$axios.get('/cart/create').then(res => {
                if(res.data?.ownershipCookie){
                    CartCookie.ownershipCookie = res.data.ownershipCookie
                }
                CartCookie.cartId = res.data.orderFormId;
            }).catch(err => {
                return null
            })
        }else{
            storeContext.commit('setCartQuantity',cookie.quantity);
        }
        storeContext.commit('setCartId',CartCookie);
    }
}
