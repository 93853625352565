export default async (context,inject) => {
    if(!context.store.state.images.images){
        var images = await context.$axios.get("storyblok/stories",{
            params : { 
                flushcache : 'yes',
                per_page: 100,
                starts_with : 'system/images'
            }
        }).then(res => {
            images = {}
            for(var story of res.data.stories){
                if(story.content){
                    images[story.slug] = {
                        light : story.content.light,
                        dark : story.content.dark,
                        gray : story.content.gray
                    }
                }
            }
            return images;
        }).catch(err => {
            return {};
        })
        context.store.commit("images/setImages",images);
    }
    inject('__img', (key) => {
        key = key.toLowerCase();
        const img = context.store.state.images.images[key] ? context.store.state.images.images[key] : null;
        return img;
    });
}