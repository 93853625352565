export const state = () => ({
    x: 0,
    y: 0,
    hover : false,
    doubleHover : false,
    smallHover : false,
    smallWhiteHover : false,
})
export const mutations = {
    set(state,pointer){
        state.x = pointer.x;
        state.y = pointer.y;
    },
    setHover(state,v){
        state.hover = v;
        state.doubleHover = false;
        state.smallHover = false;
        state.smallWhiteHover = false;
    },
    setDoubleHover(state,v){
        state.hover = false;
        state.doubleHover = v;
        state.smallHover = false;
        state.smallWhiteHover = false;
    },
    setSmallHover(state,v){
        state.hover = false;
        state.doubleHover = false;
        state.smallHover = v;
        state.smallWhiteHover = false;
    },
    setSmallWhiteHover(state,v){
        state.hover = false;
        state.doubleHover = false;
        state.smallHover = false;
        state.smallWhiteHover = v;
    },
    resetHovers(state){
        state.hover = false;
        state.doubleHover = false;
        state.smallHover = false;
        state.smallWhiteHover = false;
    }
}