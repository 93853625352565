
export default {
    // layout: 'empty',
    props : ["error"],
    computed: {
      displayMode(){
        return this.$store.state.layout.displayMode
      }
    }
}
