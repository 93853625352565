export const state = () => ({
    jwt : null,
    decodedJwt: null,
    profile: null,
    proceedWithoutLogin: false,
});
export const mutations = {
    setAuth(state, value){
        state.jwt = value;
        var jwt = require('jsonwebtoken');
        var decoded = jwt.decode(value);
        state.decodedJwt = decoded;
    },
    setProfile(state, obj){
        state.profile = obj;
    },
    logout(state,v){
        for(var i in state){
            state[i] = null;
        }
    },
    setProceedWithoutLogin(state,v){
        state.proceedWithoutLogin = v;
    }
}
export const actions = {
    async authenticate(storeContext,obj){
        var jwt = require('jsonwebtoken');
        var token = jwt.sign(obj.data, 'gothamSecret');
        obj.context.$cookies.set('vtexAuth',token,{path: '/', maxAge: 60 * 60 * 24 * 7})
        storeContext.commit('setAuth',token);
        await storeContext.dispatch('getProfile',obj);
    },
    async getProfile({commit},obj){
        await this.$axios.get('/user/profile',{params:{email:obj.data.email}}).then(res => {
            commit('setProfile',res.data);
        }).catch(err => { return null});
    }
}
