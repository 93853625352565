import {v4 as uuidV4} from 'uuid';
var dialogOpen = (data) => {
    return new Promise((resolve, reject) => {
        data.id = uuidV4();
        data.$nuxt = data.$nuxt ? data.$nuxt : window.$nuxt;
        data.promiseResolver = resolve;
        data.promiseRejecter = reject;
        data.$nuxt.$emit("dialogOpen",data);
    })
};
export default ({ app, $config }, inject) => {
    inject("dialogConfirm",data => {
        return dialogOpen(data);
    })
    inject("dialogUserLogin",(data) => {
        var data = {
            view: 'userLogin',
            html: true,
            animation: 'fade',
            backdropClose: true,
            loader: true,
            ...data
        };
        return dialogOpen(data);
    })
    inject("dialogUserRegister",(data) => {
        var data = {
            view: 'userRegister',
            html: true,
            animation: 'fade',
            backdropClose: true,
            loader: true,
            ...data
        };
        return dialogOpen(data);
    })
    inject("dialogUserLoginCheckout",() => {
        var data = {
            view: 'userLoginCheckout',
            html: true,
            animation: 'fade',
            backdropClose: true,
            loader: true,
        };
        return dialogOpen(data);
    })
    inject("dialogEditProfile",(data) => {
        var data = {
            view: 'editProfile',
            html: true,
            animation: 'fade',
            backdropClose: true,
            loader: true,
            data:data
        };
        return dialogOpen(data);
    })
    inject("dialogManageAddress",(data) => {
        var data = {
            view: 'manageAddress',
            html: true,
            animation: 'fade',
            backdropClose: true,
            loader: true,
            data:data
        };
        return dialogOpen(data);
    })
    inject("dialogAddToCart",(data) => {
        var data = {
            view: 'addToCart',
            html: true,
            animation: 'fade',
            backdropClose: true,
            loader: true,
            data:data
        };
        return dialogOpen(data);
    })
    inject("dialogSpareParts",(blok) => {
        var data = {
            view: 'spareParts',
            html: true,
            animation: 'fade',
            backdropClose: true,
            loader: true,
            data:blok
        };
        return dialogOpen(data);
    })
}