// DOCUMENTAZIONE -> https://github.com/apvarun/toastify-js/blob/master/README.md
import Toastify from 'toastify-js' 
import "~/assets/scss/toast.css"

let options = {
    duration: 3000,
    close: false,
    gravity: "top",
    position: "center",
    stopOnFocus: true
}
export default async (context,inject) => {
    inject("toastSuccess", (s) => {
        return Toastify({
            ...options,
            text: context.$__(s),
            className: 'success',
            style: {
                background: '#85BA47'
            }
        }).showToast()
    })
    inject("toastError", (s) => {
        return Toastify({
            ...options,
            text: context.$__(s),
            className: 'error',
            style: {
                background: '#DB4437'
            }
        }).showToast();
    })
    inject("toastWarning", (s) => {
        return Toastify({
            ...options,
            text: context.$__(s),
            className: 'warning',
            style: {
                background: '#eda702'
            }
        }).showToast();
    })
    inject("toastInfo", (s) => {
        return Toastify({
            ...options,
            text: context.$__(s),
            className: 'info',
            style: {
                background: '#3B5998'
            }
        }).showToast();
    })
}