export const state = () => ({
    alreadySubscribed : false,
    newsletterChecked: false
});
export const mutations = {
    setAlreadySubscribed(state, value){
        state.alreadySubscribed = value;
        
    },
    setNewsletterChecked(state, value){
        state.newsletterChecked = value
    }

}
export const actions = {
    async checkAlreadySubscribed(storeContext,email){
        this.$axios.post('/newsletter/check-already-subscribed',{recipient: email}).then(res => {
            storeContext.commit('setAlreadySubscribed',res.data.found)
        }).catch(err => {
            return null;
        })
    },
    async checkNewsletter(storeContext, value){
        storeContext.commit('setNewsletterChecked', value)
    }
}
