export const state = () => ({
  menuOpen: false,
  isWebpSupported: false,
  modalOpen: false,
  filtersOpen: false,
  popupOpen: false,
  productName: null,
  searchOpen: false,
  displayMode : 'light',
  mobileSearch: false
});

export const mutations = {
  switchDisplayMode(state){
    state.displayMode = state.displayMode == 'dark' ? 'light' : 'dark';
  },
  setDisplayMode(state,value){
    state.displayMode = value;
  },
  setMenuOpen(state,value){
    state.menuOpen = value;
    state.filtersOpen = false;
  },
  setMobileSearch(state,value){
    state.mobileSearch = value;
  },
  setIsWebpSupported(state,val){
    state.isWebpSupported = val;
  },
  setProductName(state,val){
    state.productName = val;
  },
  setModalOpen(state,val){
    state.modalOpen = val;
  },
  setSearchOpen(state,val){
    state.searchOpen = val;
  },
  setFiltersOpen(state,val){
    state.filtersOpen = val;
  },
  setPopupOpen(state,v){
    state.popupOpen = v;
  }
}