export default async (context) => {
    if(context.route.query&&context.route.query.authCookieValue){
        context.store.dispatch('user/authenticate',{
            data: {
                name:context.route.query.authCookieName,
                value:context.route.query.authCookieValue, 
                email:context.route.query.email}, 
            context:context
        });
        if(process.client) window.location = context.route.path; else context.redirect(context.route.path); 
    }
}