export const state = () => ({
    labels : null,
});
export const mutations = {
    setLabels(state, data){
        state.labels = data;
    },
    setSingleLabel(state,data){
        state.labels[data.key] = data.value
    }
}
