Math.radians = function(degrees) {
	return degrees * Math.PI / 180;
}
export default ({ app, $config, store, route, mixins }, inject) => {
    inject("storyblokFlushCache", (force) => {
        if(app.$storyapi && (app.$config.currentEnviroment != 'production' || force)){
            app.$storyapi.flushCache();
        }
    })
    inject('addZero', (s) =>{
        var n = parseInt(s);
        if(!n && n != 0) return s;
        if(n >= 10) return s;
        return "0"+n;
    });
    inject('testEmpty', (s) => {
        return s == null || s == undefined || s == '';
    });
    inject('testEmail', (s) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(s).toLowerCase());
    });
    inject('testPhone', (s) => {
        var re = /^((00|\+)\d{2}[\. ]??)??3\d{2}[\. ]??\d{6,7}([\,\;]((00|\+)\d{2}[\. ]??)??3\d{2}[\. ]??\d{6,7})*$/;
        return re.test(s);
    })
    inject('testCF', (s) => {
        var re = /^[A-Z]{6}[A-Z0-9]{2}[A-Z][A-Z0-9]{2}[A-Z][A-Z0-9]{3}[A-Z]$/;
        return re.test(s);
    })
    inject("testIban",(s) => {
        if(s.length != 27) return false;
        var re = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
        return re.test(s);
    })
    inject("testLength", (s) => {
        return s.s.length == s.l;
    })
    inject('formatDate', (d,f) => {
        var moment = require('moment');
        moment.locale(route.params.lang);
        var data = new moment(d);
        return data ? data.format(f?f:'DD/MM/YYYY') : 'invalid date';
    })
    inject('formatDateTime', (d,f) => {
        var moment = require('moment');
        moment.locale(route.params.lang);
        var data = new moment(d);
        return data ? data.format(f?f:'DD MMMM YYYY | LT') : 'invalid date';
    })
    inject('formatDateIso',d => {
        var moment = require('moment');
        moment.locale(route.params.lang);
        return moment(d).toISOString();
    })
    inject('testPassword', (obj) => {
        if(obj.value){
            if(obj.length){
                if(obj.value.length >= obj.length){
                    return true;
                }
            }else{
                return true
            }
        }else{
            return false;
        }
    })
    inject('correctAssetUrl',(s,$nuxt = $nuxt) => {
        $nuxt = !$nuxt ? app : $nuxt;
        return s.replace("https://a.storyblok.com",$nuxt.$config.axiosBaseURL+"storyblok/image");
    })
    inject("head",(story) => {
        var metadata = story.content.metadata;
        if(!metadata){
            metadata = {
              title : story.content.title ? story.content.title : null,
              description: story.content.text ? app.$storyapi.richTextResolver.render(story.content.text).substring(0, 156)+' ...' : null,
              follow: "follow",
              image: story.content.mainimage && story.content.mainimage.filename ? story.content.mainimage.filename : '',
            }

        }
        if(metadata){
            if(!metadata.title) metadata.title = story.name ? story.name : null;
            if(!metadata.description) metadata.description = story.content.text ? app.$storyapi.richTextResolver.render(story.content.text).substring(0, 156)+' ...' : null;
            if(!metadata.image) metadata.image = story.content.mainimage && story.content.mainimage.filename ? story.content.mainimage.filename : '';
            if(!metadata.follow) metadata.follow = 'follow';


            var title = metadata.title ? metadata.title.replace(/<\/?[^>]+(>|$)/g, "") : app.$__('metaDefaultTitle');
            var description = metadata.description ? metadata.description.replace(/<\/?[^>]+(>|$)/g, "") : app.$__('metaDefaultDescription');
            var robots = metadata.follow == 'follow' ? 'index, follow' : 'noindex, nofollow';
            var host = app && app.context && app.context.req && app.context.req.headers && app.context.req.headers.host ? app.context.req.headers.host : window.location.host;
            var path = app && app.context && app.context.req && app.context.req.originalUrl ? app.context.req.originalUrl : window.location.pathname

            return {
                title: title + " - " +  app.$__('metaPostfixTitle'),
                meta: [
                    { hid: 'og:type', property: 'og:type', content: 'article' },
                    { hid: 'og:title', property: 'og:title', content: title + ' - ' + app.$__('metaPostfixTitle')},
                    { hid: 'og:site_name', property: 'og:site_name', content: app.$__('metaSiteName')},
                    { hid: 'description', name: 'description', content: description },
                    { hid: 'og:url', property: 'og:url', content: 'https://'+host+path},
                    { hid: 'og:description', property: 'og:description', content: description },
                    { hid: 'og:image', property: 'og:image', content: metadata.image },
                    { hid: 'og:image:width', property: 'og:image:width', content: '1200' },
                    { hid: 'og:image:height', property: 'og:image:height', content: '630' },
                    { hid : 'robots', name : 'robots', content : robots },
                    { rel: 'msapplication-square70x70logo', content: 'favicon/ms-icon-70x70.png' },
                    { rel: 'msapplication-square150x150logo', content: 'favicon/ms-icon-150x150.png' },
                    { rel: 'msapplication-square310x310logo', content: 'favicon/ms-icon-310x310.png' },
                ]
            }
        }
    })
    inject("trimText",(str, maxLen, strip = false, separator = "") => {
        str = strip ? str.replace(/<\/?[^>]+(>|$)/g, "") : str;
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen)) + " [...]";
    })
    inject("elementHover",e => {
        app.store.commit("pointer/hoverElement",e);
    })
    inject("elementOut",e => {
        app.store.commit("pointer/outElement",e);
    })
    inject("singleLetter",s =>{
        var tmp = s.split(" ");
        var title = "";
        for(var j in tmp){
            title += '<div>';
            s = Array.from(tmp[j]);
            for(var i in s){
                s[i] = '<span><span>'+s[i]+'</span></span>';
            }
            // if(j!=0){
            //     title += '<span><span>'+' '+'</span></span>';
            // }
            title += s.join('');
            title += '</div>';
        }
        return title;
    });
    inject("getSystemComponentStory", s => {
        let version = $config.storyblokVersion;
        app.$storyblokFlushCache(true);
        var lang = '';
        if(route.params && route.params.lang &&  route.params.lang != $config.defaultLanguage) lang = route.params.lang+"/";
        let endpoint = 'cdn/stories/'+lang+'system/' + s;
        return app.$storyapi.get(endpoint,{
            version: version,
        })
        .then((res) => { 
            return res.data.story.content;
        })
        .catch(() => {
            return null;
        });
    })
    inject("formatPrice",p => {
        p = p == undefined || p == null ? 0 : p;
        p =  parseFloat(p);
        return (p).toLocaleString('it-IT', { style: 'currency', currency: 'eur'})
    })
    inject("formatPriceSpan",n => {
        if(isNaN(n)) return 'NAN';
        if(!(n).toLocaleString) return n;
        n = (n).toLocaleString('it-IT', { style: 'currency', currency: 'eur'});
        n = n.split(",");
        return n[0] +'<span>,'+ n[1] +'</span>';
    })
    inject("replaceFirstTagWith",(s,t) => {
        if(s && t){
            s = app.$storyapi.richTextResolver.render(s);
            if(s){
                var dom = process.client ? document : null;
                if(process.server){
                    const jsdom = require("jsdom");
                    const { JSDOM } = jsdom;
                    dom = (new JSDOM('<!DOCTYPE html></html>')).window.document;
                }
                s = s.trim();
                var vD = dom.createElement("div");
                vD.innerHTML = s;
                var rs = [];
                for (var i = 0; i < vD.children.length; i++) {
                    rs.push(vD.children[i].innerHTML);
                }
                if(t.includes('.')){
                    t = t.replace(".","");
                    return `<div class="${t}">${rs.join(`</div><div class="${t}">`)}</div>`;
                }else{
                    return `<${t}>${rs.join(`</${t}><${t}>`)}</${t}>`;
                }
            }
        }
    })
    inject("formatBytes",(a,b=2) => {
        if(0===a) return "0 Bytes";
        const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
        return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]
    })
    inject('lerpfunction',(f,t = 50,internalNuxt = null) => {
        if(internalNuxt){
            internalNuxt.$nextTick(() => { setTimeout(f,t) }); 
        }else if($nuxt){
            $nuxt.$nextTick(() => { setTimeout(f,t) }); 
        } 
    })
    inject("parseVtexImageUrl",(id,w='800',h='auto') => {
        var s = app.$config.axiosBaseURL+"vtex/image/"+ id + "/"
        if(!s.includes('localhost')) s.replace('http://','https://');
        return s;
    })
    inject("evalMessagesErrors",(cart,context) => {
        var errorMessages = [];
        if(cart.messages && cart.messages.length > 0){
            errorMessages = cart.messages.filter(m => {return m.status == 'error'})
            for(var i in errorMessages){
                context.$toastError(errorMessages[i].text);
            }
            var warningMessages = cart.messages.filter(m => {return m.status == 'warning'})
            for(var i in warningMessages){
                context.$toastWarning(warningMessages[i].text);
            }
            app.$axios.post('/cart/messages/clear',{cartId:context.storedCart.id}).then(res => {return true}).catch(err => {return false});
        }
        return errorMessages.length === 0;
    })
    inject("thronLocalizedTag",(t) => {
        if(!t || !t.name) return t;
        const currentLanguage = route && route.params && route.params.lang && route.params.lang.length == 2 ? route.params.lang : $config.defaultLanguage;
        const findedText = t.name.find(e => { return e.lang == currentLanguage });
        const computedText = findedText ? findedText : t.name[0];
        return computedText.value ? computedText.value : computedText;
    })
}