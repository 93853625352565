export default{
    data(){
        return{
            animationHolder: null,
            layoutHeight: 0,
        }
    },
    computed: {
        wW : {
            get(){
                return this.$store.state.scroll.wW;
            },
            set(w){
                this.$store.commit('scroll/setWw',w);
            }
        },
        wH : {
            get(){
                return this.$store.state.scroll.wH;
            },
            set(h){
                this.$store.commit('scroll/setWh',h);
            }
        },
    },
    mounted() {
        this.setWindowDimensions();
       if( navigator && navigator.userAgent.indexOf("CriOS") != -1  || !navigator.userAgent.match(/iPhone/i) ){
            window.addEventListener('resize',      this.setWindowDimensions , { passive : true })
            window.addEventListener('"orientationchange"',    this.invertWindowDimensions , { passive : true })   
        }else {
            window.addEventListener('"orientationchange"',   this.invertWindowDimensions , { passive : true })   
        }
        window.addEventListener('scroll',this.handleScroll,{passive: true})
        this.animate();
    },
    methods: {
        setWindowDimensions(){
            this.wW = window.innerWidth;
            this.wH = window.innerHeight;
        },
        invertWindowDimensions(){
            this.wH = window.innerWidth;
            this.wW = window.innerHeight;

        },
        handleScroll(e){
            if(this.modalOpen || this.openMenu){
                e.preventDefault();
                e.stopPropagation();
                window.scrollTo(0,this.$store.state.scroll.top);
                return false;
            }
            this.$store.commit("scroll/setTop",this.getScrollTop());
        },
        getScrollTop(){
            if(typeof pageYOffset != 'undefined'){
                return pageYOffset;
            } else{
                var B = document.body;
                var D = document.documentElement;
                D = (D.clientHeight) ? D : B;
                return D.scrollTop;
            }
        },
        animate(){
            var layoutHeight = this.$el.clientHeight;
            if(layoutHeight != this.layoutHeight){
                this.layoutHeight = layoutHeight;
                this.$nuxt.$emit('sizeChanged')
            }
            this.animationHolder = window.requestAnimationFrame(this.animate);
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize",this.setWindowDimensions);
    },
}