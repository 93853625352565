export default async (context,inject) => {
    class piwik {
        updateCart(obj){
            if(window && window.dataLayer && Array.isArray(window.dataLayer)){
                window.dataLayer.push({
                    event: 'cart_update',
                    ...obj
                })
            }
        }
        orderConfirmation(obj){
            if(window && window.dataLayer && Array.isArray(window.dataLayer)){
                window.dataLayer.push({
                    event: 'order_confirmation',
                    ...obj
                })
            }
        }

        async getProducts(items){
            var promiseArr = [];
            for(var i in items){
                if(items[i].productId){
                    var product = context.$axios.post('/products/search/new',{
                        pagination: {
                            page_size: 1,
                            page: 1
                        },
                        filters : {
                            productId: items[i].productId
                        }
                    })
                }
                promiseArr.push(product)
            }

            const products = await Promise.all(promiseArr).then(res => {
                var arr = [];
                for(var i in res){
                    if(res[i].data.products && res[i].data.products[0]) arr.push(res[i].data.products[0])
                }
                return arr;

            }).catch(err => {
                return null;
            })

            return products

        }
    }

    var piwikClass = new piwik();
    inject("piwikUpdateCart", async (cartObj) => {

        const products = await piwikClass.getProducts(cartObj.items);

        var payload = {
            products : [],
            cart_amount: (cartObj.value/100).toFixed(2),
        }
        for(var i in products){
            var obj = {
                sku: products[i].productId,
                name: products[i].productName,
                category: products[i].categories && products[i].categories[0] ? products[i].categories[0].split(' '): [],
                price: products[i].price,
                quantity: 1 
            }
            payload.products.push(obj);
        }

        piwikClass.updateCart(payload);
    })

    inject("piwikOrderConfirmation", async (orderObj) => {
        const products = await piwikClass.getProducts(orderObj.items);

        var totalizersItems = orderObj.totalizers.find(t => t.id == 'Items')
        var subTotal = totalizersItems && totalizersItems.value ? totalizersItems.value : 0;

        var totalizersDiscounts = orderObj.totalizers.find(t => t.id == 'Discounts')
        var discount = totalizersDiscounts && totalizersDiscounts.value ? Math.abs(totalizersDiscounts.value) : 0;
        
        var totalizersShipping = orderObj.totalizers.find(t => t.id == 'Shipping')
        var shipping = totalizersShipping && totalizersShipping.value ? totalizersShipping.value : 0;

        var payload = {
            products : [],
            order_id: orderObj.id,
            grand_total: (orderObj.value/100).toFixed(2),
            discount: (discount/100).toFixed(2),
            shipping: (shipping/100).toFixed(2),
            subtotal: (subTotal/100).toFixed(2),
            tax: (0/100).toFixed(2) //non è un valore che ci viene fornito da Vtex, quindi come da loro richiesta lo valorizziamo a 0 https://app.clickup.com/t/861mehqzt?comment=90100008219094&threadedComment=90040005613896
        }
        for(var i in products){
            var item = orderObj.items.find(item => item.productId == products[i].productId);
            var quantity = item ? item.quantity : 1;
            var obj = {
                sku: products[i].productId,
                name: products[i].productName,
                category: products[i].categories && products[i].categories[0] ? products[i].categories[0].split(' '): [],
                price: products[i].price,
                quantity: quantity
            }
            payload.products.push(obj);
        }
        piwikClass.orderConfirmation(payload);

        return new Promise((resolve,reject) => { // solo perché devo far in modo che sia bloccante finché il giro non è finito
            resolve(true);
        })
    })
}