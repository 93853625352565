export const state = () => ({
    top: 0,
    wH : 0,
    wW : 0,
    mw: 0,
});
export const mutations = {
    setWw(state,val){
        state.wW = val;
        state.mw = state.wW / 36;
    },
    setWh(state,val){
        state.wH = val;
    },
    setTop(state,val){
        state.top = val;
    }
}