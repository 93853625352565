
import 'remixicon/fonts/remixicon.css';
import handleWindow from '~/mixins/handleWindow';
export default {
  mixins : [handleWindow],
  mounted() {
    this.$nextTick(()=>{
      if(window.matchMedia){
        let mediaQueryObj = window.matchMedia('(prefers-color-scheme: dark)');
        let isDarkMode = mediaQueryObj.matches;
        this.lightMode = isDarkMode ? 'dark' : 'light';
      }
    })
    
  },
  computed: {
    modalOpen(){
      return this.$store.state.modal.open;
    },
    menuOpen : {
        get(){
            return this.$store.state.layout.menuOpen;
        },
        set(v){
            this.$store.commit("layout/setMenuOpen",v)
        }
    },
    popupOpen(){
      return this.$store.state.layout.popupOpen;
    },
    searchOpen : {
        get(){
            return this.$store.state.layout.searchOpen;
        },
        set(v){
            this.$store.commit("layout/setSearchOpen",v)
        }
    },
    lightMode : {
        get(){
            return this.$store.state.layout.displayMode;
        },
        set(v){
            this.$store.commit("layout/setDisplayMode",v)
        }
    }
  }
}
