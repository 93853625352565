export const state = () => ({
    jwt: null,
    user : null,
    email : null,
    _uid : null
})
export const mutations = {
    setUser(state,token){
        var jwt = require('jsonwebtoken');
        var decoded = jwt.decode(token);

        state.jwt = token;
        state.user = decoded.user
        state.email = decoded.email
        state._uid = decoded._uid
    },
    clearUser(state){
        state.user = null
        state.email = null
        state._uid = null
    }
}
export const actions = {
    async authenticate(storeContext,obj){
        var jwt = require('jsonwebtoken');
        var token = jwt.sign(obj.data, 'gothamSecret');
        obj.context.$cookies.set('staffAuth',token,{path: '/', maxAge: 60 * 60 * 2})

        storeContext.commit('setUser',token);
    }
}