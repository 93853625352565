import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ddf8ee78 = () => interopDefault(import('../pages/empty.vue' /* webpackChunkName: "pages/empty" */))
const _24625e5a = () => interopDefault(import('../pages/ie-is-not-allowed-here.vue' /* webpackChunkName: "pages/ie-is-not-allowed-here" */))
const _94f63c6e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6d3d5c72 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _2f7b082c = () => interopDefault(import('../pages/_lang/brands/index.vue' /* webpackChunkName: "pages/_lang/brands/index" */))
const _08584604 = () => interopDefault(import('../pages/_lang/cart/index.vue' /* webpackChunkName: "pages/_lang/cart/index" */))
const _7260c3b8 = () => interopDefault(import('../pages/_lang/checkout/index.vue' /* webpackChunkName: "pages/_lang/checkout/index" */))
const _66be7dcc = () => interopDefault(import('../pages/_lang/compare.vue' /* webpackChunkName: "pages/_lang/compare" */))
const _9706abdc = () => interopDefault(import('../pages/_lang/login-operatore.vue' /* webpackChunkName: "pages/_lang/login-operatore" */))
const _67f3677c = () => interopDefault(import('../pages/_lang/pages/index.vue' /* webpackChunkName: "pages/_lang/pages/index" */))
const _66a67f4d = () => interopDefault(import('../pages/_lang/productfinder/index.vue' /* webpackChunkName: "pages/_lang/productfinder/index" */))
const _1a5814c2 = () => interopDefault(import('../pages/_lang/products/index.vue' /* webpackChunkName: "pages/_lang/products/index" */))
const _0e0e83ea = () => interopDefault(import('../pages/_lang/stories/index.vue' /* webpackChunkName: "pages/_lang/stories/index" */))
const _14d3b3e9 = () => interopDefault(import('../pages/_lang/user/index.vue' /* webpackChunkName: "pages/_lang/user/index" */))
const _9571c4cc = () => interopDefault(import('../pages/_lang/checkout/add-address.vue' /* webpackChunkName: "pages/_lang/checkout/add-address" */))
const _1fe52374 = () => interopDefault(import('../pages/_lang/checkout/billing-address.vue' /* webpackChunkName: "pages/_lang/checkout/billing-address" */))
const _371e3250 = () => interopDefault(import('../pages/_lang/checkout/payment.vue' /* webpackChunkName: "pages/_lang/checkout/payment" */))
const _3eb3782e = () => interopDefault(import('../pages/_lang/checkout/personal-data.vue' /* webpackChunkName: "pages/_lang/checkout/personal-data" */))
const _38806f13 = () => interopDefault(import('../pages/_lang/checkout/thankyou.vue' /* webpackChunkName: "pages/_lang/checkout/thankyou" */))
const _3e009183 = () => interopDefault(import('../pages/_lang/user/logout.vue' /* webpackChunkName: "pages/_lang/user/logout" */))
const _5ac34161 = () => interopDefault(import('../pages/_lang/user/orders/index.vue' /* webpackChunkName: "pages/_lang/user/orders/index" */))
const _282e6d80 = () => interopDefault(import('../pages/_lang/user/profile.vue' /* webpackChunkName: "pages/_lang/user/profile" */))
const _4169c30b = () => interopDefault(import('../pages/_lang/user/orders/_orderId.vue' /* webpackChunkName: "pages/_lang/user/orders/_orderId" */))
const _32e84abc = () => interopDefault(import('../pages/_lang/brands/_slug.vue' /* webpackChunkName: "pages/_lang/brands/_slug" */))
const _663cc634 = () => interopDefault(import('../pages/_lang/pages/_slug.vue' /* webpackChunkName: "pages/_lang/pages/_slug" */))
const _24b28b10 = () => interopDefault(import('../pages/_lang/products/_aslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/index" */))
const _117bc67a = () => interopDefault(import('../pages/_lang/stories/_slug.vue' /* webpackChunkName: "pages/_lang/stories/_slug" */))
const _6c3e6b43 = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/index" */))
const _9564341e = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/_cslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/_cslug/index" */))
const _25455401 = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/_cslug/_dslug.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/_cslug/_dslug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/empty",
    component: _ddf8ee78,
    name: "empty"
  }, {
    path: "/ie-is-not-allowed-here",
    component: _24625e5a,
    name: "ie-is-not-allowed-here"
  }, {
    path: "/",
    component: _94f63c6e,
    name: "index"
  }, {
    path: "/:lang",
    component: _6d3d5c72,
    name: "lang"
  }, {
    path: "/:lang/brands",
    component: _2f7b082c,
    name: "lang-brands"
  }, {
    path: "/:lang/cart",
    component: _08584604,
    name: "lang-cart"
  }, {
    path: "/:lang/checkout",
    component: _7260c3b8,
    name: "lang-checkout"
  }, {
    path: "/:lang/compare",
    component: _66be7dcc,
    name: "lang-compare"
  }, {
    path: "/:lang/login-operatore",
    component: _9706abdc,
    name: "lang-login-operatore"
  }, {
    path: "/:lang/pages",
    component: _67f3677c,
    name: "lang-pages"
  }, {
    path: "/:lang/productfinder",
    component: _66a67f4d,
    name: "lang-productfinder"
  }, {
    path: "/:lang/products",
    component: _1a5814c2,
    name: "lang-products"
  }, {
    path: "/:lang/stories",
    component: _0e0e83ea,
    name: "lang-stories"
  }, {
    path: "/:lang/user",
    component: _14d3b3e9,
    name: "lang-user"
  }, {
    path: "/:lang/checkout/add-address",
    component: _9571c4cc,
    name: "lang-checkout-add-address"
  }, {
    path: "/:lang/checkout/billing-address",
    component: _1fe52374,
    name: "lang-checkout-billing-address"
  }, {
    path: "/:lang/checkout/payment",
    component: _371e3250,
    name: "lang-checkout-payment"
  }, {
    path: "/:lang/checkout/personal-data",
    component: _3eb3782e,
    name: "lang-checkout-personal-data"
  }, {
    path: "/:lang/checkout/thankyou",
    component: _38806f13,
    name: "lang-checkout-thankyou"
  }, {
    path: "/:lang/user/logout",
    component: _3e009183,
    name: "lang-user-logout"
  }, {
    path: "/:lang/user/orders",
    component: _5ac34161,
    name: "lang-user-orders"
  }, {
    path: "/:lang/user/profile",
    component: _282e6d80,
    name: "lang-user-profile"
  }, {
    path: "/:lang/user/orders/:orderId",
    component: _4169c30b,
    name: "lang-user-orders-orderId"
  }, {
    path: "/:lang/brands/:slug",
    component: _32e84abc,
    name: "lang-brands-slug"
  }, {
    path: "/:lang/pages/:slug",
    component: _663cc634,
    name: "lang-pages-slug"
  }, {
    path: "/:lang/products/:aslug",
    component: _24b28b10,
    name: "lang-products-aslug"
  }, {
    path: "/:lang/stories/:slug",
    component: _117bc67a,
    name: "lang-stories-slug"
  }, {
    path: "/:lang/products/:aslug/:bslug",
    component: _6c3e6b43,
    name: "lang-products-aslug-bslug"
  }, {
    path: "/:lang/products/:aslug/:bslug/:cslug",
    component: _9564341e,
    name: "lang-products-aslug-bslug-cslug"
  }, {
    path: "/:lang/products/:aslug/:bslug/:cslug/:dslug",
    component: _25455401,
    name: "lang-products-aslug-bslug-cslug-dslug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
